import { useBoolean } from '@spaceship-fspl/dynamic-config';

export enum DynamicConfigKeys {}

export enum FeatureFlagKeys {
  PERFORMANCE_UPDATES_ENABLED = 'marketing_performance_updates_enabled',
  FIRST_HOME_ENABLED = 'marketing_first_home_enabled',
  VOYAGER_GRAPH_PERCENTAGE_CHANGES_ENABLED = 'marketing_voyager_graph_percentage_changes_enabled',
}

export const useFeatureFlag = (flag: FeatureFlagKeys): boolean => {
  return useBoolean(flag, false);
};
