import {
  Box,
  Columns,
  Container,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  calcInceptionToPerformanceAsAtDateInMonths,
  stringToDate,
} from '@spaceship-fspl/helpers';
import {
  fontSize,
  getColor,
  getNumericSpace,
  rgba,
  Theme,
  useBetweenMedia,
} from '@spaceship-fspl/styles';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import {
  PerformanceCard,
  PerformanceCardProps,
} from 'components/performance-card';
import {
  SuperPrismicPortfolio,
  VoyagerPrismicPortfolio,
} from 'helpers/products';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from 'victory';
import { VictoryLabelProps } from 'victory-core';

import { PanelSection } from '../panel-section';

const PerformanceAmount = styled(Heading)`
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -2px;
`;

const PerAnnum = styled.span`
  ${fontSize({ xs: '24px', lg: '28px' })}
`;

type PortfolioData<T> = Omit<PerformanceCardProps<T>, 'variant'>;

export interface ReturnsPanelProps<T> {
  title: string;
  tagline: string;
  disclaimer: React.ReactNode;
  portfolio: PortfolioData<T>;
  isPerformanceUpdatesEnabled: boolean;
}

export function ReturnsPanel<
  T extends VoyagerPrismicPortfolio | SuperPrismicPortfolio,
>({
  title,
  tagline,
  disclaimer,
  portfolio,
  isPerformanceUpdatesEnabled,
}: ReturnsPanelProps<T>): React.ReactElement {
  const isVoyager = [
    'universe',
    'origin',
    'earth',
    'galaxy',
    'explorer',
  ].includes(portfolio.type);

  const inceptionDate = stringToDate(portfolio.inceptionDate ?? '');
  const performanceAsAtDate = stringToDate(portfolio.performanceAsAt ?? '');
  const annualisedPerformanceDataInMonths =
    calcInceptionToPerformanceAsAtDateInMonths(
      inceptionDate,
      performanceAsAtDate,
    );

  return (
    <TrackingCategory category="Returns Panel">
      <PanelSection>
        <Container>
          <Stack spaceY={{ xs: 'lg', lg: 'xl' }}>
            <Stack spaceY={{ xs: 'sm', lg: 'md' }}>
              <Heading component="h2" variant={2} align="center">
                {title}
              </Heading>

              <Text variant={1} align="center" color="neutral.080">
                {tagline}
              </Text>
            </Stack>

            {isPerformanceUpdatesEnabled ? (
              <Columns
                spaceX={{ xs: 'none', lg: 'md' }}
                spaceY={{ xs: 'lg', lg: 'xxl' }}
                alignX="center"
              >
                <Columns.Column width={{ xs: 1, lg: 2 / 5, xl: 1 / 3 }}>
                  <PerformanceCard
                    variant="light"
                    type={portfolio.type}
                    title={portfolio.title}
                    inceptionDate={portfolio.inceptionDate}
                    sinceInceptionPerformancePercentage={
                      portfolio.sinceInceptionPerformancePercentage
                    }
                    oneMonthPerformancePercentage={
                      portfolio.oneMonthPerformancePercentage
                    }
                    threeMonthPerformancePercentage={
                      portfolio.threeMonthPerformancePercentage
                    }
                    sixMonthPerformancePercentage={
                      portfolio.sixMonthPerformancePercentage
                    }
                    oneYearPerformancePercentage={
                      portfolio.oneYearPerformancePercentage
                    }
                    threeYearPerformancePercentage={
                      portfolio.threeYearPerformancePercentage
                    }
                    performanceAsAt={portfolio.performanceAsAt}
                  />
                </Columns.Column>

                <Columns.Column width={{ xs: 1, lg: 3 / 5, xl: 2 / 3 }}>
                  <Box
                    backgroundColor="indigo.100"
                    borderRadius="md"
                    height={{ xs: 430, lg: '100%' }}
                    padding={{ xs: 'sm', lg: 'lg', xl: 'xl' }}
                  >
                    <PerformanceChart
                      isVoyager={isVoyager}
                      sinceInceptionPerformancePercentage={
                        portfolio.sinceInceptionPerformancePercentage
                      }
                      oneMonthPerformancePercentage={
                        portfolio.oneMonthPerformancePercentage
                      }
                      threeMonthPerformancePercentage={
                        portfolio.threeMonthPerformancePercentage
                      }
                      sixMonthPerformancePercentage={
                        portfolio.sixMonthPerformancePercentage
                      }
                      oneYearPerformancePercentage={
                        portfolio.oneYearPerformancePercentage
                      }
                      threeYearPerformancePercentage={
                        portfolio.threeYearPerformancePercentage
                      }
                    />
                  </Box>
                </Columns.Column>

                <Columns.Column width={{ xs: 1, lg: 10 / 12 }}>
                  <Text
                    variant={3}
                    align="center"
                    color="neutral.085"
                    component="div"
                  >
                    {disclaimer}
                  </Text>
                </Columns.Column>
              </Columns>
            ) : (
              <Columns
                spaceX={{ xs: 'none', md: 'md' }}
                spaceY={{ xs: 'md', lg: 'lg' }}
              >
                <Columns.Column
                  offset={{ xs: 0, md: 1 / 12 }}
                  width={{ xs: 1, md: 5 / 12 }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    justifyContent="center"
                    backgroundColor="indigo.100"
                    borderRadius="md"
                    minHeight="205px"
                    paddingY={{ xs: 'lg', lg: 'xl' }}
                    paddingX={{ xs: 'lg', md: 'xl', lg: 'xxl' }}
                  >
                    <Stack spaceY={{ xs: 'xxxs', lg: 'xxs' }}>
                      <PerformanceAmount
                        variant={1}
                        align="center"
                        color={isVoyager ? 'indigo.020' : 'mint.030'}
                      >
                        {portfolio.oneYearPerformancePercentage === 'N/A'
                          ? 'x.xx'
                          : portfolio.oneYearPerformancePercentage}
                        %
                      </PerformanceAmount>
                      {portfolio.type === 'galaxy' ||
                      portfolio.type === 'explorer' ? (
                        <Text align="center" variant={2} color="neutral.000">
                          Investment performance data for the {portfolio.title}{' '}
                          will be available after one full year of history. The{' '}
                          {portfolio.title} was funded on{' '}
                          {portfolio.inceptionDate}.
                        </Text>
                      ) : (
                        <Text align="center" variant={2} color="neutral.000">
                          Over the year ending {portfolio.performanceAsAt}
                        </Text>
                      )}
                    </Stack>
                  </Box>
                </Columns.Column>

                <Columns.Column width={{ xs: 1, md: 5 / 12 }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    justifyContent="center"
                    backgroundColor="indigo.100"
                    borderRadius="md"
                    minHeight="205px"
                    paddingY={{ xs: 'lg', lg: 'xl' }}
                    paddingX={{ xs: 'lg', md: 'xl', lg: 'xxl' }}
                  >
                    <Stack spaceY={{ xs: 'xxxs', lg: 'xxs' }}>
                      <PerformanceAmount
                        variant={1}
                        align="center"
                        color={isVoyager ? 'indigo.020' : 'mint.030'}
                      >
                        {portfolio.sinceInceptionPerformancePercentage === 'N/A'
                          ? 'x.xx'
                          : portfolio.sinceInceptionPerformancePercentage}
                        %<PerAnnum>&nbsp;pa</PerAnnum>
                      </PerformanceAmount>

                      {portfolio.type === 'galaxy' ||
                      portfolio.type === 'explorer' ? (
                        <Text align="center" variant={2} color="neutral.000">
                          Investment performance data for the {portfolio.title}{' '}
                          will be available after one full year of history. The{' '}
                          {portfolio.title} was funded on{' '}
                          {portfolio.inceptionDate}.
                        </Text>
                      ) : (
                        <Text align="center" variant={2} color="neutral.000">
                          {isVoyager
                            ? 'Annualised since the Funded Date*'
                            : 'Annualised over the life of the fund'}{' '}
                          ({portfolio.inceptionDate} to{' '}
                          {portfolio.performanceAsAt}) (
                          {annualisedPerformanceDataInMonths} months)
                        </Text>
                      )}
                    </Stack>
                  </Box>
                </Columns.Column>

                <Columns.Column
                  offset={{ xs: 0, md: 1 / 12 }}
                  width={{ xs: 1, md: 10 / 12 }}
                >
                  <Text
                    variant={3}
                    align="center"
                    color="neutral.085"
                    component="div"
                  >
                    {disclaimer}
                  </Text>
                </Columns.Column>
              </Columns>
            )}
          </Stack>
        </Container>
      </PanelSection>
    </TrackingCategory>
  );
}

const PerformanceChart: React.FC<
  React.PropsWithChildren<{
    isVoyager: boolean;
    oneMonthPerformancePercentage?: string;
    threeMonthPerformancePercentage?: string;
    sixMonthPerformancePercentage?: string;
    oneYearPerformancePercentage?: string;
    threeYearPerformancePercentage?: string;
    sinceInceptionPerformancePercentage?: string;
  }>
> = ({
  isVoyager,
  oneMonthPerformancePercentage,
  threeMonthPerformancePercentage,
  sixMonthPerformancePercentage,
  oneYearPerformancePercentage,
  threeYearPerformancePercentage,
  sinceInceptionPerformancePercentage,
}) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [chartContainerRect, setChartContainerRect] = useState({
    width: chartContainerRef.current?.getBoundingClientRect().width,
    height: chartContainerRef.current?.getBoundingClientRect().height,
  });
  const graphData = useMemo(() => {
    if (
      !oneMonthPerformancePercentage ||
      !threeMonthPerformancePercentage ||
      !sixMonthPerformancePercentage ||
      !oneYearPerformancePercentage ||
      !threeYearPerformancePercentage ||
      !sinceInceptionPerformancePercentage
    ) {
      return [];
    }
    return [
      { label: '1M', x: 1, y: Number(oneMonthPerformancePercentage ?? 0) },
      { label: '3M', x: 2, y: Number(threeMonthPerformancePercentage ?? 0) },
      { label: '6M', x: 3, y: Number(sixMonthPerformancePercentage ?? 0) },
      { label: '1Y', x: 4, y: Number(oneYearPerformancePercentage ?? 0) },
      { label: '3Y', x: 5, y: Number(threeYearPerformancePercentage ?? 0) },
      {
        label: `Since\n${isVoyager ? 'Funded\nDate' : 'inception\ndate'}`,
        x: 6,
        y: Number(sinceInceptionPerformancePercentage ?? 0),
      },
    ];
  }, [
    isVoyager,
    oneMonthPerformancePercentage,
    threeMonthPerformancePercentage,
    sixMonthPerformancePercentage,
    oneYearPerformancePercentage,
    threeYearPerformancePercentage,
    sinceInceptionPerformancePercentage,
  ]);

  useEffect(() => {
    const updateWidth = (): void => {
      setChartContainerRect({
        width: chartContainerRef.current?.getBoundingClientRect().width,
        height: chartContainerRef.current?.getBoundingClientRect().height,
      });
    };

    if (chartContainerRef.current) {
      updateWidth();
    }

    // Victory chart likes to maintain aspect ratio which doesn't
    // work with our design. We can't use the solution we used in
    // the line chart (using our own svg container) because this
    // would not work with their axis component. Instead we need to
    // add the window resize listener:
    // https://github.com/FormidableLabs/victory/issues/396#issuecomment-348182325
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [graphData]);

  return (
    <Box ref={chartContainerRef} position="relative" height="100%">
      <Box position="absolute">
        <BarChart
          data={graphData}
          height={chartContainerRect.height}
          width={chartContainerRect.width}
        />
      </Box>
    </Box>
  );
};

const CHART_TICK_INTERVAL = 5;

const BarChart: React.FC<
  React.PropsWithChildren<{
    data: Array<{ x: number; y: number; label?: string }>;
    height?: number;
    width?: number;
  }>
> = ({ data, height = 0, width = 0 }) => {
  const theme: Theme = useContext(ThemeContext);
  const isMobile = useBetweenMedia('xs', 'lg');

  if (!height || !width || data.length === 0) {
    return null;
  }

  let minY = 0;
  let maxY = 0;

  data.forEach((dataPoint) => {
    minY = dataPoint.y < minY ? dataPoint.y : minY;
    maxY = dataPoint.y > maxY ? dataPoint.y : maxY;
  });

  const minYTick = minY - CHART_TICK_INTERVAL - (minY % CHART_TICK_INTERVAL);
  const maxYTick = maxY + CHART_TICK_INTERVAL - (maxY % CHART_TICK_INTERVAL);
  const yTickCount = (maxYTick - minYTick) / CHART_TICK_INTERVAL + 1;

  return (
    <VictoryChart
      height={height}
      width={width}
      minDomain={{
        x: 0.5,
        y: minYTick,
      }}
      maxDomain={{ y: maxYTick }}
      padding={{
        left: getNumericSpace(isMobile ? 'xl' : 'xxl'),
        right: getNumericSpace('sm'),
        top: getNumericSpace('md'),
        bottom: getNumericSpace('md'),
      }}
    >
      {/* X Axis */}
      <VictoryAxis
        tickValues={data.reduce((tickValues, _dataPoint, index) => {
          // Hack to show the grid lines between bars (rather than on top)
          // https://github.com/FormidableLabs/victory/issues/1262#issuecomment-472163532
          return [...tickValues, index + 1, index + 1.5];
        }, [] as Array<number>)}
        tickLabelComponent={
          <NegativeAwareTickLabel data={data} isMobile={isMobile} />
        }
        style={{
          axis: { stroke: rgba('indigo.015', 0.4) },
          grid: {
            stroke: ({ tick }: { tick: number }) =>
              tick % 1 === 0 ? 'none' : rgba('indigo.015', 0.4),
          },
        }}
      />

      {/* Y Axis */}
      <VictoryAxis
        dependentAxis={true}
        tickValues={Array(yTickCount)
          .fill('')
          .map((_, index) => {
            // Give explicit tick values to force the set intervals
            // https://stackoverflow.com/questions/71252375/victory-chart-give-range-of-10-in-y-axis
            return minYTick + CHART_TICK_INTERVAL * index;
          })}
        tickFormat={(t) => `${t}%`}
        crossAxis={false}
        style={{
          axis: { stroke: rgba('indigo.015', 0.4) },
          grid: { stroke: rgba('indigo.015', 0.4) },
          tickLabels: {
            fontSize: isMobile ? 12 : 20,
            fontFamily: theme.fontFamilies['text'],
            padding: getNumericSpace('xxs'),
            fill: getColor('indigo.015'),
          },
        }}
      />

      <VictoryBar
        data={data}
        barRatio={0.8}
        padding={0}
        style={{
          data: { fill: getColor('indigo.020') },
          labels: { fill: 'none' },
        }}
      />
    </VictoryChart>
  );
};

const TICK_LABEL_LINE_HEIGHT = 1.2;

// By default, Victory chart shows the tick label below the 0 y axis,
// but this gets hidden behind the bar if the value is negative.
// We need to move the label above the 0 y axis if the value is negative.
const NegativeAwareTickLabel: React.FC<
  React.PropsWithChildren<
    Exclude<VictoryLabelProps, 'datum'> & { isMobile: boolean; datum?: number }
  >
> = ({ data, isMobile, datum, y = 0, ...props }) => {
  const theme: Theme = useContext(ThemeContext);

  if (!data || datum === undefined || datum % 1 !== 0) {
    return null;
  }

  const fontSize = isMobile ? 12 : 18;
  const lineHeightPx = fontSize * TICK_LABEL_LINE_HEIGHT;
  const dataPoint = data[datum - 1];
  const lineCount = ((dataPoint.label ?? '').match(/\n/g)?.length ?? 0) + 1;

  return (
    <VictoryLabel
      {...props}
      datum={datum}
      text={dataPoint.label}
      y={dataPoint.y < 0 ? y - (lineCount + 1) * lineHeightPx : y}
      style={{
        fontSize,
        fontFamily: theme.fontFamilies['text'],
        fill: getColor('indigo.015'),
      }}
      lineHeight={TICK_LABEL_LINE_HEIGHT}
    />
  );
};
