import { Box, Card, Text } from '@spaceship-fspl/components';
import { IconComponent } from '@spaceship-fspl/icons-web';
import { Color, getColor, getSpace, TextVariant } from '@spaceship-fspl/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const InfoPanelWrapper = styled(Card)`
  display: flex;
  flex-direction: row;
  border-radius: ${getSpace('sm')};
  overflow: hidden;
  margin-bottom: ${getSpace('sm')};
  text-align: left;
`;

const InfoPanelFill = styled.div<{
  borderLeftColor: Color;
}>`
  min-width: 11px;
  background-color: ${({ borderLeftColor }) => getColor(borderLeftColor)};
`;

type Props = {
  color: Color;
  icon: IconComponent;
  textVariant?: TextVariant;
  variant?: 'default' | 'old';
};

export const InfoPanel: React.FC<PropsWithChildren<Props>> = ({
  children,
  color,
  icon,
  textVariant = 2,
  variant = 'default',
}) => {
  const Icon = icon;
  const isDefault = variant === 'default';

  return (
    <InfoPanelWrapper
      boxShadow={isDefault ? 'md' : 'none'}
      backgroundColor={isDefault ? 'neutral.000' : 'neutral.050'}
    >
      {isDefault ? <InfoPanelFill borderLeftColor={color} /> : null}

      <Box
        paddingLeft={{
          xs: isDefault ? 'xs' : 'sm',
          sm: isDefault ? 'xs' : 'lg',
        }}
        paddingTop={isDefault ? 'sm' : 'md'}
      >
        <Icon color={color} size="md" />
      </Box>
      <Box paddingX="xs" paddingY={isDefault ? 'sm' : 'md'}>
        <Text variant={textVariant} color="neutral.085">
          {children}
        </Text>
      </Box>
    </InfoPanelWrapper>
  );
};
